$(document).ready(function(){
    _alert = (icon = '', title = '', message = '', confirmButton = false, cancelButton = false, timer = false, redirectUrl = "") => {
        Swal.fire({
          title: title,
          html: message,
          icon: icon, 
          showConfirmButton: confirmButton,
          showCancelButton: cancelButton,
          confirmButtonText: "Oke",
          confirmButtonColor: "#ff0055",
          cancelButtonColor: "#999999",
          reverseButtons: true,
          focusConfirm: true,
          focusCancel: true,
          timer: timer,
          timerProgressBar: true,
        }).then( function(isConfirm){
    
          if(confirmButton){

            if (isConfirm.value) {

                if (!(redirectUrl == "")) {

                    window.location.replace(redirectUrl);

                } else {

                    location.reload();

                }


            } else {

                Swal.close();

            }

          }
    
        });
    }
    
    _alertAjax = (icon = '', title = '', message = '', confirmButton = false, cancelButton = false, timer = false, redirectUrl = "") => {
        Swal.fire({
            title: title,
            html: message,
            icon: icon, 
            showConfirmButton: confirmButton,
            showCancelButton: cancelButton,
            confirmButtonText: "Oke",
            confirmButtonColor: "#ff0055",
            cancelButtonColor: "#999999",
            reverseButtons: true,
            focusConfirm: true,
            focusCancel: true,
            timer: timer,
            timerProgressBar: true,
        }).then( function(isConfirm){
    
            if(confirmButton){
  
              if (isConfirm.value) {
  
                  if (!(redirectUrl == "")) {
  
                      window.location.replace(redirectUrl);
  
                  }
  
  
              } else {
  
                  Swal.close();
  
              }
  
            }
      
          });
    }

    $(document).on('keypress', 'form', function(e){
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }
    });
    
});